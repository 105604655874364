// export const baseUrl = 'http://3.134.4.45:3000/';
// export const baseUrl = 'https://api.racehorsetrax.com/';
// export const baseUrl = 'http://161.35.120.195:1100/api/v1/';
export const baseUrl = 'https://api.phonesheetapp.com/api/v1/';
// export const baseUrl = 'http://localhost:8000/api/v1/';
// export const stripePublicKey =
//   'pk_test_51KOiTVEPrLk0Dnb1WmkPGqpOHDL9dJSHWCDKMUSyQOIzdwcM6QCkmneYIht0GkzbktPFWTA5BJXegfHIZrlWFJH500KRbTODp1';

export const stripePublicKey =
  'pk_live_51NAfcQIvHcDAJkKkm0KlZw1Yk30uEsNPww1aP5R8XPyEt54TZ1UXOY0nxDKNxLOmjOdu6Gdpf9NtKi5yNoVxpYee00HyPwJDR3';

export const PLAY_STORE_URL =
  'https://play.google.com/store/apps/details?id=com.techversatile.phonesheet&hl=en_US';
export const APP_STORE_URL =
  'https://apps.apple.com/us/app/phonesheet/id6449737270';
export const IMAGE_BASE_URL =
  'https://farmhand-data.s3.us-east-2.amazonaws.com/';

export const APP_EMAIL = 'support@phonesheetapp.com';
export const APP_CONTACT = '(120) 456-789-123';
