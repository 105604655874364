import React, {createContext, useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  SET_PHONE_SHEET_LISTING_DATA,
  SET_PHONE_SHEET_UPDATE_DATA,
  SET_SUBSCRIPTON_DATA,
  SET_USERS_LISTING,
  SHOW_MESSAGE,
} from 'shared/constants/ActionTypes';
import jwtAxios, {setAuthToken} from './index';
import {useNavigate} from 'react-router-dom';

const JWTAuthContext = createContext();
const JWTAuthActionsContext = createContext();

export const useJWTAuth = () => useContext(JWTAuthContext);

export const useJWTAuthActions = () => useContext(JWTAuthActionsContext);

const JWTAuthAuthProvider = ({children}) => {
  const navigate = useNavigate();
  const [firebaseData, setJWTAuthData] = useState({
    user: null,
    isAuthenticated: false,
    isLoading: true,
    role: 1,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    const getAuthUser = () => {
      const token = localStorage.getItem('JWTPhoneSheet');
      if (!token) {
        setJWTAuthData({
          user: undefined,
          isLoading: false,
          isAuthenticated: false,
          role: 0,
        });
        return;
      }

      // console.log('InsideUseffect-===========================================');
      setAuthToken(token);
      jwtAxios
        .get('/auth/getMe')
        .then(({data}) => {
          // console.log('data-sucess', data?.data);
          if (!data?.data) return clearLocalStorage();
          setJWTAuthData({
            user: data?.data,
            isLoading: false,
            isAuthenticated: true,
            role: 1,
          });
        })
        .catch(() => {
          clearLocalStorage();
        });
    };

    getAuthUser();
  }, []);

  const signInUser = async ({email, password}) => {
    dispatch({type: FETCH_START});
    try {
      const {data} = await jwtAxios.post('auth/login', {
        email,
        password,
      });
      // setAuthToken(data.data.accessToken);
      // let token = localStorage.getItem('JWTPhoneSheet');
      // getMe();
      // console.log('Data========Login========', data);
      // console.log('--token--', token);
      localStorage.setItem('JWTPhoneSheet', data?.data?.accessToken);
      setAuthToken(data?.data?.accessToken);
      const res = await jwtAxios.get('/auth/getMe');
      setJWTAuthData({
        user: res.data.data.data,
        isAuthenticated: true,
        isLoading: false,
        role: 1,
      });
      // console.log(
      //   'AfterLoginInformation ----------------------------------------',
      //   res.data.data,
      // );
      if (res.data.data.subscribed == false) {
        navigate('/subscription');
      } else {
        getMe();
      }

      dispatch({type: FETCH_SUCCESS});
    } catch (error) {
      setJWTAuthData({
        ...firebaseData,
        isAuthenticated: false,
        isLoading: false,
        role: 0,
      });

      dispatch({
        type: FETCH_ERROR,
        payload: error?.response?.data?.msg || 'Something went wrong',
      });
    }
  };

  const getMe = async () => {
    await jwtAxios
      .get('/auth/getMe')
      .then((res) => {
        // console.log('sucess--===', res?.data?.user);
        let user = {...res?.data?.data.data, role: 'user'};
        setJWTAuthData({
          user: user,
          isAuthenticated: true,
          isLoading: false,
          role: 1,
        });
        dispatch({type: FETCH_SUCCESS});
      })
      .catch((error) => {
        // console.log('errr', error);
        dispatch({
          type: FETCH_ERROR,
          payload: error?.response?.data?.message || 'Something went wrong',
        });
      });
  };

  const signUpUser = async ({
    firstName,
    lastName,
    email,
    password,
    companyName,
  }) => {
    dispatch({type: FETCH_START});
    try {
      const data = await jwtAxios.post('auth/registerAdmin', {
        firstName: firstName,
        lastName: lastName,
        email,
        password,
        confirmPassword: password,
        companyName,
      });
      // localStorage.setItem('JWTPhoneSheet', data?.data?.accessToken);
      // setAuthToken(data?.data?.accessToken);
      // const res = await jwtAxios.get('/auth/getMe');
      // setJWTAuthData({
      //   user: res.data.data.data,
      //   isAuthenticated: true,
      //   isLoading: false,
      //   role: 1,
      // });
      // navigate('/subscription');
      // console.log('SignUpResponse===========================', data);
      if (data.status == 201) {
        dispatch({type: SHOW_MESSAGE, payload: data.data.msg});
        navigate('/home');
      }
    } catch (error) {
      setJWTAuthData({
        ...firebaseData,
        isAuthenticated: false,
        isLoading: false,
        role: 0,
      });
      // console.log('error:', error.response.data);
      dispatch({
        type: FETCH_ERROR,
        payload: error?.response?.data?.error
          ? error?.response?.data?.error[0]
          : error.response.data.msg || 'Something went wrong',
      });
    }
  };

  const logout = async () => {
    try {
      await jwtAxios.post('auth/logout', {});
      setJWTAuthData({
        user: null,
        isLoading: false,
        isAuthenticated: false,
      });
      clearLocalStorage();
      navigate('/home');
    } catch (error) {
      console.log(error);

      setJWTAuthData({
        user: null,
        isLoading: false,
        isAuthenticated: false,
      });
      clearLocalStorage();
      navigate('/home');
    }
  };

  const clearLocalStorage = () => {
    localStorage.removeItem('JWTPhoneSheet');
    setAuthToken();
    setJWTAuthData({
      user: null,
      isLoading: false,
      isAuthenticated: false,
    });
    dispatch({type: SET_PHONE_SHEET_LISTING_DATA, payload: null});
    dispatch({type: SET_PHONE_SHEET_UPDATE_DATA, payload: null});
    dispatch({type: SET_USERS_LISTING, payload: null});
    dispatch({type: SET_SUBSCRIPTON_DATA, payload: null});
    localStorage.clear();
  };
  return (
    <JWTAuthContext.Provider
      value={{
        ...firebaseData,
      }}
    >
      <JWTAuthActionsContext.Provider
        value={{
          signUpUser,
          signInUser,
          logout,
        }}
      >
        {children}
      </JWTAuthActionsContext.Provider>
    </JWTAuthContext.Provider>
  );
};
export default JWTAuthAuthProvider;

JWTAuthAuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
