/* eslint-disable no-sparse-arrays */
import React, {useState} from 'react';
import PropsTypes from 'prop-types';
import {Box} from '@mui/material';
import {useLocation} from 'react-router-dom';
import {useEffect} from 'react';

const AppContentViewWrapper = ({children, ...rest}) => {
  const location = useLocation();
  const [defaultWidth, setDefaultWidth] = useState({xl: 1650});

  useEffect(() => {
    if (
      location.pathname ==
      ('/home' || '/about-us' || '/contat-us' || '/services')
    ) {
      setDefaultWidth(null);
    }
  }, [, defaultWidth]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        // maxWidth: defaultWidth,
        mx: {xl: 'auto'},
        width: {xl: '100%'},
      }}
      {...rest}
    >
      {children}
    </Box>
  );
};

export default AppContentViewWrapper;

AppContentViewWrapper.propTypes = {
  children: PropsTypes.node,
};
