/* eslint-disable no-sparse-arrays */
import React, {useState} from 'react';
import {AppSuspense} from '../../index';
import {
  anonymousStructure,
  authorizedStructure,
  unAuthorizedStructure,
} from '../../../pages';
import AppFooter from '../AppLayout/components/AppFooter';
import AppErrorBoundary from '../AppErrorBoundary';
import generateRoutes from '../../utility/RouteGenerator';
import {useAuthUser} from '../../utility/AuthHooks';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import AppContentViewWrapper from './AppContentViewWrapper';
import {useLocation} from 'react-router-dom';
// import {useState} from 'react';
import {useEffect} from 'react';

const AppContentView = ({sxStyle}) => {
  const {user, isAuthenticated} = useAuthUser();
  const location = useLocation();
  const [defaultPadding, setDefaultPadding] = useState({
    xs: 5,
    md: 7.5,
    xl: 12.5,
  });

  useEffect(() => {
    if (
      location.pathname ==
      ('/home' || '/about-us' || '/contat-us' || '/services')
    ) {
      setDefaultPadding(null);
    }
  }, [, location]);

  return (
    <AppContentViewWrapper className='app-content-view' id='page'>
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          p: defaultPadding,
          ...sxStyle,
        }}
        className='app-content'
      >
        <AppSuspense sx={{height: '100vh'}}>
          <AppErrorBoundary>
            {generateRoutes({
              isAuthenticated: isAuthenticated,
              userRole: user?.role,
              unAuthorizedStructure,
              authorizedStructure,
              anonymousStructure,
            })}
          </AppErrorBoundary>
        </AppSuspense>
      </Box>
      <AppFooter />
    </AppContentViewWrapper>
  );
};

export default AppContentView;

AppContentView.propTypes = {
  sxStyle: PropTypes.object,
};
