import React from 'react';
import {Navigate} from 'react-router-dom';
import {RoutePermittedRole} from 'shared/constants/AppConst';

const ToDo = React.lazy(() => import('./ToDo'));
const ContactQuery = React.lazy(() => import('./ContactQuery'));
const SubscriptionPlan = React.lazy(() => import('./SubscriptionPlans'));
export const appsConfig = [
  {
    permittedRole: RoutePermittedRole.user,
    path: [
      '/apps/todo/label/:label',
      '/apps/todo/label/:label/:id',
      '/apps/todo/:folder',
      '/apps/todo/:folder/:id',
    ],
    element: <ToDo />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/apps/paymentApproval',
    element: <Navigate to='/apps/todo/all' />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/apps/contactQuery',
    element: <ContactQuery />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/apps/subscriptionPlans',
    element: <SubscriptionPlan />,
  },
];
