import React, {useState, useEffect} from 'react';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import {Fonts} from '../../../shared/constants/AppEnums';
import AppAnimate from '../../../@crema/core/AppAnimate';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {Grid} from '@mui/material';
import {ReactComponent as SuccessLogo} from '../../../assets/user/success-tick.svg';
import {ReactComponent as FailLogo} from '../../../assets/user/x-circle.svg';
import axios from 'axios';
import {baseUrl} from 'config/utils';

const VerifyAccountToken = () => {
  const navigate = useNavigate();

  const [tokenError, setTokenError] = useState(false);
  const [counter, setCounter] = useState(10);

  const [params] = useSearchParams();
  const token = params.get('token');

  let interval;

  const verifyUserEmail = async (token) => {
    try {
      const doc = await axios.patch(`${baseUrl}auth/verifyUser/${token}`);

      if (doc.status == 200) {
        let pointer = 10;
        interval = setInterval(() => {
          if (pointer > 0) {
            setCounter((current) => current - 1);
            pointer--;
          } else {
            clearInterval(interval);
            navigate('/');
          }
        }, 1000);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (token) {
      verifyUserEmail(token);
    } else {
      setTokenError(true);
      console.log('invalid token');
    }
    return () => clearInterval(interval);
  }, []);

  return (
    <AppAnimate animation='transition.slideUpIn' delay={200}>
      <Box
        sx={{
          pb: 6,
          py: {xl: 8},
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Card
          sx={{
            maxWidth: 576,
            width: '100%',
            textAlign: 'center',
            padding: {xs: 8, lg: 12, xl: '48px 64px'},
            overflow: 'hidden',
            boxShadow:
              '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
          }}
        >
          <Box
            sx={{
              mb: {xs: 3, xl: 4},
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Grid item xs={12} lg={6}>
              <Box
                sx={{
                  width: '100%',
                  height: '100%',
                  textAlign: 'center',
                  mb: 5,
                  '& svg': {
                    width: '100%',
                    height: '125px',
                    display: 'inline-block',
                    paddingRight: {xs: 0, lg: 2.5},
                  },
                }}
              >
                {tokenError ? <FailLogo /> : <SuccessLogo />}
              </Box>
            </Grid>
            <Box
              sx={{
                color: tokenError ? '#BE1E24' : '#2080BD',
                mb: 1.5,
                fontWeight: Fonts.BOLD,
                fontSize: 24,
              }}
            >
              {tokenError ? 'Account Verification Failed' : 'Account Verified!'}
            </Box>
            <Box
              sx={{
                m: 2,
                color: '#808080',
                fontWeight: Fonts.MEDIUM,
                fontSize: 18,
              }}
            >
              {tokenError
                ? 'Verification Link is invalid. Request Again.'
                : 'You have successfully verified your email.'}
            </Box>
            {!tokenError && (
              <Box
                sx={{
                  m: 2,
                  color: '#808080',
                  fontWeight: Fonts.MEDIUM,
                  fontSize: 12,
                }}
              >
                You will be redirected to Login Page in {counter} Seconds.
              </Box>
            )}
          </Box>
        </Card>
      </Box>
    </AppAnimate>
  );
};

export default VerifyAccountToken;
