import React, {useEffect, useState} from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Button,
} from '@mui/material';
import {MdPayment} from 'react-icons/md';
import axios from 'axios';
import {baseUrl, stripePublicKey} from 'config/utils';
import moment from 'moment';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import Payment from './payment';
import {AppLoader} from '@crema';
import {useDispatch} from 'react-redux';
import {useSelector} from 'react-redux';
import {
  SET_IS_SUBSCRIBED,
  SET_SUBSCRIPTON_DATA,
  SET_LOADING,
  SET_DELETE_USER_MODAL,
  SHOW_MESSAGE,
} from 'shared/constants/ActionTypes';
import AppConfirmDialog from '@crema/core/AppConfirmDialog';

const SubscriptionInfo = () => {
  const isDeleteDialogOpen = useSelector(
    ({phoneSheet}) => phoneSheet.deleteUserModal,
  );

  const setDeleteDialogOpen = () => {
    dispatch({type: SET_DELETE_USER_MODAL, payload: false});
  };
  const dispatch = useDispatch();
  const isLoading = useSelector(({phoneSheet}) => phoneSheet.loading);
  const subscriptionData = useSelector(
    ({phoneSheet}) => phoneSheet.subscriptionData,
  );
  const isSubscribed = useSelector(({phoneSheet}) => phoneSheet.isSubscribed);
  const stripePromise = loadStripe(stripePublicKey);

  const [cancelSubscriptionStatus, setCancelSubscriptionStatus] =
    useState(false);

  const [paidSubscriptionStatus, setPaidSubscriptionStatus] = useState(true);
  const onClickOkay = async () => {
    const JWT = localStorage.getItem('JWTPhoneSheet');

    try {
      const doc = await axios.delete(
        `${baseUrl}subscription/cancelSubscription`,
        {headers: {authorization: JWT}},
      );

      dispatch({type: SET_DELETE_USER_MODAL, payload: false});
      if (doc.status == 200) {
        dispatch({
          type: SHOW_MESSAGE,
          payload: 'Your subscription has been cancelled successfully',
        });
        checkUserSusbcription();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const checkUserSusbcription = async () => {
    try {
      dispatch({type: SET_LOADING, payload: true});
      console.log(localStorage.getItem('JWTPhoneSheet'));
      let doc = await axios.get(`${baseUrl}auth/getMe`, {
        headers: {
          Authorization: localStorage.getItem('JWTPhoneSheet'),
        },
      });

      dispatch({type: SET_IS_SUBSCRIBED, payload: doc?.data?.data?.subscribed});
      if (doc?.data?.data?.subscribed) {
        // console.log(
        //   'SubscriptionCondition========================================',
        //   doc?.data?.data?.companySubscription[0]?.paid,
        // );

        setPaidSubscriptionStatus(
          doc?.data?.data?.companySubscription[0]?.paid,
        );

        if (doc?.data?.data?.companySubscription[0]?.paid == true) {
          const data = await axios.get(
            `${baseUrl}subscription/mySubscriptionDetails`,
            {
              headers: {
                Authorization: localStorage.getItem('JWTPhoneSheet'),
              },
            },
          );

          // debugger;

          const d = {
            planName:
              data.data.data?.subscriptionInfo == false
                ? data?.data?.data?.subscription.planName
                : data?.data?.data?.subscriptionInfo?.plan.nickname,
            startDate:
              data.data.data?.subscriptionInfo == false
                ? moment(data?.data?.data?.subscription.createdAt)
                : moment.unix(
                    data?.data?.data?.subscriptionInfo?.current_period_start,
                  ),
            endDate:
              data.data.data?.subscriptionInfo == false
                ? moment(data?.data?.data?.subscription.subscriptionExpiresAt)
                : moment.unix(
                    data?.data?.data?.subscriptionInfo?.current_period_end,
                  ),
            nextBilling:
              data.data.data?.subscriptionInfo == false
                ? moment(data?.data?.data?.subscription.subscriptionExpiresAt)
                : moment.unix(
                    addOneDayToTimestamp(
                      data?.data?.data?.subscriptionInfo?.current_period_end,
                    ),
                  ),
          };

          setCancelSubscriptionStatus(data?.data?.data?.subscription.cancelled);
          dispatch({
            type: SET_SUBSCRIPTON_DATA,
            payload: d,
          });
        }
      }
      dispatch({type: SET_LOADING, payload: false});
    } catch (error) {
      console.log(error);
      dispatch({type: SET_LOADING, payload: true});
    }
  };
  function addOneDayToTimestamp(timestamp) {
    const date = new Date(timestamp * 1000);
    date.setDate(date.getDate() + 1);
    return Math.floor(date.getTime() / 1000);
  }

  useEffect(() => {
    checkUserSusbcription();
  }, []);
  return (
    <Box>
      {isLoading == false ? (
        <>
          {paidSubscriptionStatus == true && isSubscribed == true ? (
            <>
              <Box sx={{display: 'flex', alignItems: 'center', mb: 2}}>
                <MdPayment sx={{fontSize: '48px', marginRight: '12px'}} />
                <Typography variant='h5' fontWeight='bold'>
                  Subscription Information
                </Typography>
              </Box>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow sx={{fontSize: 16}}>
                      <TableCell>Plan</TableCell>
                      <TableCell>Start Date</TableCell>
                      <TableCell>End Date</TableCell>

                      {cancelSubscriptionStatus == false && (
                        <TableCell>Renewal Date</TableCell>
                      )}

                      <TableCell>Payment Method</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>{subscriptionData?.planName}</TableCell>
                      <TableCell>
                        {subscriptionData?.startDate?.format('MMMM D, YYYY')}
                      </TableCell>
                      <TableCell>
                        {subscriptionData?.endDate?.format('MMMM D, YYYY')}
                      </TableCell>

                      {cancelSubscriptionStatus == false && (
                        <TableCell>
                          {subscriptionData?.nextBilling?.format(
                            'MMMM D, YYYY',
                          )}
                        </TableCell>
                      )}

                      <TableCell>Credit Card</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: '2%',
                }}
              >
                <Button
                  variant='contained'
                  color='secondary'
                  onClick={() => {
                    dispatch({type: SET_DELETE_USER_MODAL, payload: true});
                  }}
                  disabled={cancelSubscriptionStatus}
                >
                  Cancel Subscription
                </Button>
              </Box>

              {cancelSubscriptionStatus && (
                <Box>
                  <Typography
                    variant='h5'
                    fontWeight='normal'
                    sx={{color: 'red'}}
                  >
                    {`Your subscription has been canceled. Your account will be valid ${subscriptionData.endDate.format(
                      ' DD MMMM YYYY',
                    )}`}
                  </Typography>
                </Box>
              )}

              <AppConfirmDialog
                open={isDeleteDialogOpen}
                onDeny={setDeleteDialogOpen}
                onConfirm={onClickOkay}
                title={'Are you sure you want to cancel subscription?'}
                dialogTitle={'Cancel Subscription'}
              />
            </>
          ) : (
            <>
              <Elements stripe={stripePromise}>
                <Payment />
              </Elements>
            </>
          )}
        </>
      ) : (
        <>
          <AppLoader />
        </>
      )}
    </Box>
  );
};
export default SubscriptionInfo;
