import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  HIDE_MESSAGE,
  SHOW_MESSAGE,
  TOGGLE_APP_DRAWER,
  UPDATING_CONTENT,
  SET_MODAL_OPEN,
  SET_UPDATE_SUBSCRIPTION_DATA,
  RELOAD_SUCCESS,
  SET_PHONE_SHEET_UPDATE_DATA,
} from 'shared/constants/ActionTypes';

export const fetchStart = () => {
  return (dispatch) => dispatch({type: FETCH_START});
};

export const fetchSuccess = () => {
  return (dispatch) => dispatch({type: FETCH_SUCCESS});
};
export const updatingContent = () => {
  return (dispatch) => dispatch({type: UPDATING_CONTENT});
};

export const fetchError = (error) => {
  return (dispatch) => dispatch({type: FETCH_ERROR, payload: error});
};

export const showMessage = (message) => {
  return (dispatch) => dispatch({type: SHOW_MESSAGE, payload: message});
};
export const onToggleAppDrawer = () => {
  return (dispatch) => dispatch({type: TOGGLE_APP_DRAWER});
};

export const hideMessage = () => {
  return (dispatch) => dispatch({type: HIDE_MESSAGE});
};
export const setModalOpen = () => {
  return (dispatch) => dispatch({type: SET_MODAL_OPEN});
};
export const setUpdateSubscriptionData = () => {
  return (dispatch) => dispatch({type: SET_UPDATE_SUBSCRIPTION_DATA});
};
export const reloadSuccess = () => {
  return (dispatch) => dispatch({type: RELOAD_SUCCESS});
};
export const setPhoneSheetData = () => {
  return (dispatch) => dispatch({type: SET_PHONE_SHEET_UPDATE_DATA});
};
