import React from 'react';
import VerifyAccount from './VerifyAccount';
// const Signin = React.lazy(() => import('./Signin'));
const ForgotPassword = React.lazy(() => import('./ForgetPassword'));
const ResetPassword = React.lazy(() => import('./ResetPassword'));
// const SignUp = React.lazy(() => import('./Signup'));
const Subscription = React.lazy(() => import('./Checkout'));
const NewSignin = React.lazy(() => import('./Signin/SigninNew'));
const NewSignUp = React.lazy(() => import('./Signup/SignupNew'));
const TermsAndConditionsPage = React.lazy(() => import('./TermsAndConditions'));
const PrivacyPolicyPage = React.lazy(() => import('./PrivacyPolicy'));
const LandingPage = React.lazy(() => import('./Landing'));
const AboutUsNew = React.lazy(() => import('./AboutUsNew'));
const ContactUsNew = React.lazy(() => import('./ContactUsNew'));
const PaymentModule = React.lazy(() => import('./Payment'));

export const authRouteConfig = [
  {
    path: '/home',
    element: <LandingPage />,
  },
  {
    path: '/about-us',
    element: <AboutUsNew />,
  },
  {
    path: '/contact-us',
    element: <ContactUsNew />,
  },
  {
    path: '/signin',
    element: <NewSignin />,
  },
  {
    path: '/forget-password',
    element: <ForgotPassword />,
  },
  {
    path: '/forget-password',
    element: <ForgotPassword />,
  },
  {
    path: '/reset-password',
    element: <ResetPassword />,
  },
  {
    path: '/verify-account',
    element: <VerifyAccount />,
  },
  {
    path: '/signup',
    element: <NewSignUp />,
  },
  {
    path: '/terms-and-conditions',
    element: <TermsAndConditionsPage />,
  },
  {
    path: '/privacy-policy',
    element: <PrivacyPolicyPage />,
  },
];
export const checkoutConfig = [
  {
    path: '/subscription', // If path is changed, then do change it as well in ---> /src/@crema/core/AppLayout/index.js
    element: <Subscription />,
  },
  {
    path: '/payment',
    element: <PaymentModule />,
  },
];
