import {
  SET_PHONE_SHEET_LISTING_DATA,
  SET_LOADING,
  SET_USERS_LISTING,
  SET_SUBSCRIPTON_PLAN,
  SET_SUBSCRIPTON_DATA,
  SET_IS_SUBSCRIBED,
  SET_DELETE_USER_MODAL,
  SET_DELETE_ID,
  SET_SUBSCRIPTION_PLAN_TYPE,
} from 'shared/constants/ActionTypes';

const INIT_STATE = {
  loading: false,
  phoneSheetData: [],
  usersListing: [],
  subscrpitionPlan: {},
  subscriptionData: {},
  isSubscribed: false,
  deleteUserModal: false,
  deletedID: null,
  subscriptionPlanType: true,
};

const phoneSheetReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_PHONE_SHEET_LISTING_DATA: {
      return {
        ...state,
        phoneSheetData: action.payload,
      };
    }
    case SET_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case SET_USERS_LISTING: {
      return {
        ...state,
        usersListing: action.payload,
      };
    }
    case SET_SUBSCRIPTON_PLAN: {
      return {
        ...state,
        subscrpitionPlan: action.payload,
      };
    }
    case SET_SUBSCRIPTON_DATA: {
      return {
        ...state,
        subscriptionData: action.payload,
      };
    }
    case SET_IS_SUBSCRIBED: {
      return {
        ...state,
        isSubscribed: action.payload,
      };
    }
    case SET_DELETE_USER_MODAL: {
      return {
        ...state,
        deleteUserModal: action.payload,
      };
    }
    case SET_DELETE_ID: {
      return {
        ...state,
        deletedID: action.payload,
      };
    }
    case SET_SUBSCRIPTION_PLAN_TYPE: {
      return {
        ...state,
        subscriptionPlanType: action.payload,
      };
    }
    default:
      return state;
  }
};
export default phoneSheetReducer;
