import React from 'react';
import PropTypes from 'prop-types';
import {Box, TextField} from '@mui/material';
import axios from 'axios';
import {baseUrl} from 'config/utils';
import {useDispatch} from 'react-redux';
import {FETCH_ERROR, SHOW_MESSAGE} from 'shared/constants/ActionTypes';
import {useState, useEffect} from 'react';
import {Grid, Button, Typography} from '@mui/material';
import {AppGridContainer} from '@crema';
import {Fonts} from 'shared/constants/AppEnums';

const PersonalInfo = () => {
  const JWT = localStorage.getItem('JWTPhoneSheet');
  const dispatch = useDispatch();

  const [userDetails, setUserDetails] = useState({});
  const [oldName, setOldName] = useState('');
  const [oldLastName, setOldLastName] = useState('');

  const getMe = async () => {
    let doc = await axios.get(`${baseUrl}auth/getMe`, {
      headers: {
        Authorization: JWT,
      },
    });

    setUserDetails({
      _id: doc?.data?.data?._id,
      email: doc?.data?.data?.email,
      firstName: doc?.data?.data?.firstName,
      lastName: doc?.data?.data?.lastName,
    });
    setOldName(doc?.data?.data?.firstName);
    setOldLastName(doc?.data?.data?.lastName);
  };
  const updateData = async () => {
    try {
      if (userDetails.firstName == '')
        return dispatch({
          type: FETCH_ERROR,
          payload: "Full Name field can't be empty",
        });
      else if (userDetails.lastName == '')
        return dispatch({
          type: FETCH_ERROR,
          payload: "Full Name field can't be empty",
        });
      else if (
        oldName == userDetails.firstName &&
        oldLastName == userDetails.lastName
      )
        return dispatch({
          type: FETCH_ERROR,
          payload: 'Kindly update the fields',
        });
      let doc = await axios.patch(
        `${baseUrl}auth/updateProfile`,
        {
          firstName: userDetails.firstName,
          lastName: userDetails.lastName,
        },
        {
          headers: {
            Authorization: JWT,
          },
        },
      );
      if (doc.status == 200) {
        localStorage.setItem('JWTPhoneSheet', doc.data?.data?.accessToken);
        dispatch({type: SHOW_MESSAGE, payload: 'Updated Successfully'});

        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getMe();
  }, []);
  return (
    <Box
      sx={{
        position: 'relative',
        maxWidth: 550,
      }}
    >
      <Typography
        component='h3'
        sx={{
          fontSize: 16,
          fontWeight: Fonts.BOLD,
          mb: {xs: 3, lg: 4},
        }}
      >
        {'Personal Info'}
      </Typography>
      <AppGridContainer spacing={4}>
        <Grid item xs={12} md={6}>
          {/* <TextField
            name='displayName'
            fullWidth
            value={userDetails.firstName}
            label={'First Name'}
            onChange={(e) => {
              setUserDetails({...userDetails, firstName: e.target.value});
            }}
          /> */}
          <TextField
            id='outlined-basic'
            label='First Name'
            variant='outlined'
            value={userDetails?.firstName}
            defaultValue={userDetails?.firstName}
            fullWidth={true}
            // autoFocus={true}
            onChange={(e) => {
              setUserDetails({...userDetails, firstName: e.target.value});
            }}
            InputLabelProps={{shrink: userDetails.firstName}}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id='outlined-basic'
            label='Last Name'
            variant='outlined'
            value={userDetails?.lastName}
            fullWidth={true}
            InputLabelProps={{shrink: userDetails.lastName}}
            onChange={(e) => {
              setUserDetails({...userDetails, lastName: e.target.value});
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id='outlined-basic'
            label='Email'
            variant='outlined'
            value={userDetails?.email}
            fullWidth={true}
            InputProps={{
              readOnly: true,
            }}
            disabled
            InputLabelProps={{shrink: userDetails.email}}
          />
        </Grid>

        <Grid item xs={12} md={12}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Button
              sx={{
                position: 'relative',
                minWidth: 100,
              }}
              color='primary'
              variant='contained'
              onClick={() => {
                updateData();
              }}
            >
              {'Save Changes'}
            </Button>
          </Box>
        </Grid>
      </AppGridContainer>
    </Box>
  );
};

export default PersonalInfo;

PersonalInfo.propTypes = {
  setFieldValue: PropTypes.func,
  values: PropTypes.string,
};
