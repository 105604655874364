import React, {useState} from 'react';
// import {IconButton} from '@mui/material';
import PropTypes from 'prop-types';
// import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import AppNotificationContent from './AppNotificationContent';
// import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
// import AppTooltip from '../AppTooltip';
// import {alpha} from '@mui/material/styles';

const AppNotifications = ({
  drawerPosition,
  // tooltipPosition,
  isMenu,
  sxNotificationContentStyle,
}) => {
  const [showNotification, setShowNotification] = useState(false);

  return (
    <>
      {isMenu ? <></> : <></>}

      <Drawer
        anchor={drawerPosition}
        open={showNotification}
        onClose={() => setShowNotification(false)}
      >
        <AppNotificationContent
          sxStyle={sxNotificationContentStyle}
          onClose={() => setShowNotification(false)}
        />
      </Drawer>
    </>
  );
};

export default AppNotifications;

AppNotifications.defaultProps = {
  drawerPosition: 'right',
  tooltipPosition: 'bottom',
};

AppNotifications.propTypes = {
  drawerPosition: PropTypes.string,
  tooltipPosition: PropTypes.string,
  isMenu: PropTypes.bool,
  sxNotificationContentStyle: PropTypes.object,
};
