import React, {useState, useEffect} from 'react';
import {useAuthMethod} from '../../../../utility/AuthHooks';
import {Box} from '@mui/material';
// import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Fonts} from '../../../../../shared/constants/AppEnums';
import {useNavigate} from 'react-router-dom';
import PropTypes from 'prop-types';
import {baseUrl} from 'config/utils';
import axios from 'axios';
// import {userPlaceHolder} from 'assets/images';
// import {AppLoader} from '@crema';
const UserInfo = ({color}) => {
  const {logout} = useAuthMethod();
  const navigate = useNavigate();
  const [userData, setUserData] = useState({});
  // const [imageLoading, setImageLoading] = useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  useEffect(() => {
    getUserData();
  }, []);
  const getUserData = async () => {
    // setIsLoading(true);
    const JWT = localStorage.getItem('JWTPhoneSheet');
    try {
      let data = await axios.get(`${baseUrl}auth/getMe`, {
        headers: {
          Authorization: JWT,
        },
      });
      // console.log('get me api response', data);

      if (data?.status == 200) {
        setUserData(data?.data?.data);

        // setTimeout(() => {
        //   setImageLoading(false);
        // }, 10000);
        // console.log('get me api response', data);
      }
    } catch (error) {
      // console.log(error, 'api payload');
      // setIsLoading(false);
    }
  };
  console.log(
    'userData=================================================',
    userData,
  );
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // const getUserAvatar = () => {
  //   if (user.displayName) {
  //     return user.displayName.charAt(0).toUpperCase();
  //   }
  //   if (user.email) {
  //     return user.email.charAt(0).toUpperCase();
  //   }
  // };

  return (
    <>
      <Box
        onClick={handleClick}
        sx={{
          py: 3,
          px: 3,
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
        }}
        className='user-info-view'
      >
        {/* {imageLoading == false ? (
          <Box sx={{py: 0.5}}>
            {userData.image ? (
              <Avatar
                sx={{
                  height: 40,
                  width: 40,
                  fontSize: 24,
                  // backgroundColor: orange[500],
                }}
                src={IMAGE_BASE_URL + userData?.image}
              />
            ) : (
              <Avatar
                sx={{
                  height: 40,
                  width: 40,
                  fontSize: 24,
                  // backgroundColor: orange[500],
                }}
                src={userPlaceHolder}
              />
            )}
          </Box>
        ) : (
          <div
            style={{
              position: 'relative',
              marginLeft: '15px',
              height: '30px',
              width: '30px',
            }}
          >
            <AppLoader />
          </div>
        )} */}
        <Box
          sx={{
            width: {xs: 'calc(100% - 62px)', xl: 'calc(100% - 72px)'},
            ml: 4,
            color: color,
          }}
          className='user-info'
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                mb: 0,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                fontSize: 16,
                fontWeight: Fonts.MEDIUM,
                color: 'inherit',
              }}
              component='span'
            >
              {userData.firstName
                ? userData.firstName + ' ' + userData.lastName
                : 'Admin User '}
            </Box>
            <Box
              sx={{
                ml: 3,
                color: 'inherit',
                display: 'flex',
              }}
            >
              <ExpandMoreIcon />
            </Box>
          </Box>
          <Box
            sx={{
              mt: -0.5,
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              color: 'inherit',
            }}
          >
            {userData.userType == 'Admin' ? 'Admin' : 'Non Admin'}
          </Box>
        </Box>
      </Box>
      <Menu
        id='simple-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            navigate('/my-profile');
          }}
        >
          My account
        </MenuItem>
        <MenuItem onClick={logout}>Logout</MenuItem>
      </Menu>
    </>
  );
};

export default UserInfo;

UserInfo.defaultProps = {
  color: 'text.secondary',
};

UserInfo.propTypes = {
  color: PropTypes.string,
};
