import React, {useEffect, useState} from 'react';
import {
  Box,
  Typography,
  Autocomplete,
  TextField,
  Grid,
  Button,
} from '@mui/material';
// import {MdPayment} from 'react-icons/md';
import axios from 'axios';
// import jwtAxios from '@crema/services/auth/jwt-auth';
import {baseUrl} from 'config/utils';
// import moment from 'moment';
import {AppGridContainer, AppLoader} from '@crema';
// import {Button} from '@mui/material';
import {Fonts} from 'shared/constants/AppEnums';
import {CardElement, useStripe, useElements} from '@stripe/react-stripe-js';
// import {Elements} from '@stripe/react-stripe-js';
// import {loadStripe} from '@stripe/stripe-js';s
import {useDispatch} from 'react-redux';
import moment from 'moment';
import {
  FETCH_ERROR,
  // SET_LOADING,
  SHOW_MESSAGE,
  SET_SUBSCRIPTON_DATA,
  SET_IS_SUBSCRIBED,
} from 'shared/constants/ActionTypes';

const Payment = () => {
  const [subscriptionSuccess, setSubscriptionSuccess] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [fullName, setFullName] = useState('');
  const [isLoading, setLoading] = useState(true);
  const stripe = useStripe();
  const elements = useElements();
  //   const [clientSecrets, setClientSecrets] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    getPlans();
  }, []);

  function addOneDayToTimestamp(timestamp) {
    const date = new Date(timestamp * 1000);
    date.setDate(date.getDate() + 1);
    return Math.floor(date.getTime() / 1000);
  }

  const getPlans = async () => {
    try {
      const subscriptionPlan = await axios.get(
        `${baseUrl}subscription/getSubscriptionPlans?paid=true`,
        {
          headers: {
            Authorization: localStorage.getItem('JWTPhoneSheet'),
          },
        },
      );
      const plans = [];
      subscriptionPlan?.data?.data?.map((item) => {
        plans.push({
          name: item.planName,
          id: item._id,
          amount: item.unitAmount,
        });
      });
      setSubscriptionPlans(plans);
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: '#32325D',
        fontFamily:
          '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#CFD7DF',
        },
      },
      invalid: {
        color: '#E25950',
        '::placeholder': {
          color: '#FFCCA5',
        },
      },
    },
  };

  const purchaseSubscription = async () => {
    try {
      //   console.log(selectedPlan);
      const doc = await axios.get(`${baseUrl}subscription/clientSecrets`, {
        headers: {
          Authorization: localStorage.getItem('JWTPhoneSheet'),
        },
      });

      //   setClientSecrets(doc.data?.data?.client_secret);

      if (!stripe || !elements || !CardElement) {
        return;
      }
      if (!selectedPlan?.id) {
        return dispatch({type: FETCH_ERROR, payload: 'Kindly select the plan'});
      }
      if (!fullName) {
        return dispatch({type: FETCH_ERROR, payload: 'Please enter the name'});
      }
      setIsSubmitting(true);

      const {paymentMethod} = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement),
        billing_details: {
          name: fullName,
        },
      });

      if (!paymentMethod) {
        console.log('Empty Card Element');
        // setCardError('Please enter your card information.');
        dispatch({
          type: FETCH_ERROR,
          payload: 'Please enter correct card information',
        });
        setIsSubmitting(false);
        return;
      }

      // dispatch({type: SET_LOADING, payload: true});
      const {id} = paymentMethod;

      await stripe.confirmCardSetup(doc.data?.data?.client_secret, {
        payment_method: id,
      });

      const sub = await axios.post(
        `${baseUrl}subscription/createEnterpriseSubscription`,
        {
          subscriptionID: selectedPlan?.id,
          paymentMethodID: id,
        },
        {
          headers: {
            Authorization: localStorage.getItem('JWTPhoneSheet'),
          },
        },
      );
      setIsSubmitting(false);
      if (sub.status == 200) {
        dispatch({
          type: SHOW_MESSAGE,
          payload: 'Subscription has been purchased successfully',
        });

        setSubscriptionSuccess(true);
        const data = await axios.get(
          `${baseUrl}subscription/mySubscriptionDetails`,
          {
            headers: {
              Authorization: localStorage.getItem('JWTPhoneSheet'),
            },
          },
        );

        dispatch({
          type: SET_SUBSCRIPTON_DATA,
          payload: {
            planName: data?.data?.data?.subscriptionInfo?.plan.nickname,
            startDate: moment.unix(
              data?.data?.data?.subscriptionInfo?.current_period_start,
            ),
            endDate: moment.unix(
              data?.data?.data?.subscriptionInfo?.current_period_end,
            ),
            nextBilling: moment.unix(
              addOneDayToTimestamp(
                data?.data?.data?.subscriptionInfo?.current_period_end,
              ),
            ),
          },
        });
        dispatch({type: SET_IS_SUBSCRIBED, payload: true});
      }
    } catch (e) {
      console.log(e);
      dispatch({type: FETCH_ERROR, payload: e.response.data.msg});
      // dispatch({type: SET_LOADING, payload: false});
    }
  };
  return (
    <>
      <Box
        sx={{
          position: 'relative',
          maxWidth: 600,
        }}
      >
        <Typography
          component='h3'
          sx={{
            fontSize: 16,
            fontWeight: Fonts.BOLD,
            mb: {xs: 3, lg: 4},
          }}
        >
          {'User Subscription'}
        </Typography>
        {isLoading ? (
          <AppLoader />
        ) : (
          <AppGridContainer spacing={4}>
            <Grid item xs={12} md={6}>
              <Autocomplete
                options={subscriptionPlans}
                getOptionLabel={(option) =>
                  `${option.name} - $${option.amount}`
                }
                renderInput={(params) => (
                  <TextField {...params} label='Select a plan' />
                )}
                onChange={(event, newValue) => {
                  setSelectedPlan(newValue);
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id='outlined-basic'
                label='Full Name'
                variant='outlined'
                value={fullName}
                fullWidth={true}
                InputLabelProps={{fullName}}
                onChange={(e) => {
                  setFullName(e.target.value);
                }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label='Card Details'
                variant='outlined'
                InputLabelProps={{shrink: true}}
                InputProps={{
                  inputComponent: CardElement,
                  inputProps: CARD_ELEMENT_OPTIONS,
                  css: {
                    border: '1px solid rgba(0, 0, 0, 0.23)',
                    borderRadius: '4px',
                    padding: '10.5px 14px',
                    height: 'auto',
                    '&:focus': {
                      borderColor: '#4a90e2',
                    },
                    '&::placeholder': {
                      color: '#999',
                    },
                    '&[class*="Mui-disabled"]': {
                      color: 'rgba(0, 0, 0, 0.26)',
                      backgroundColor: 'rgba(0, 0, 0, 0.12)',
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Button
                variant='contained'
                onClick={purchaseSubscription}
                disabled={
                  !stripe ||
                  !elements ||
                  isSubmitting ||
                  !CardElement ||
                  subscriptionSuccess
                }
              >
                {subscriptionSuccess
                  ? 'Success'
                  : isSubmitting
                  ? 'Subscribing...'
                  : 'Purchase Subscription '}
              </Button>
            </Grid>
          </AppGridContainer>
        )}
      </Box>
    </>
  );
};
export default Payment;
