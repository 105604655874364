import React from 'react';
import {RoutePermittedRole} from 'shared/constants/AppConst';

const UserDash = React.lazy(() => import('./Users/UserDash'));
const PhoneSheet = React.lazy(() => import('./phoneSheet'));
const Subscription = React.lazy(() => import('./Subscription'));
const PhoneSheetHistory = React.lazy(() => import('./phoneSheet/history'));
export const dashBoardConfigs = [
  {
    permittedRole: RoutePermittedRole.user,
    path: '/dashboards/UserDash',
    element: <UserDash />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/dashboards/PhoneSheet',
    element: <PhoneSheet />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/dashboards/PhoneSheetHistory',
    element: <PhoneSheetHistory />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/dashboards/subscription',
    element: <Subscription />,
  },
];
